import { Amplify } from 'aws-amplify';
import { Authenticator,useTheme,View,Image,Heading } from '@aws-amplify/ui-react';
import {fromCognitoIdentityPool} from "@aws-sdk/credential-providers";
import { STSClient,AssumeRoleCommand } from "@aws-sdk/client-sts";
import {identityPoolId,region,apiEndpoint} from './Auth.js';
import '@aws-amplify/ui-react/styles.css';

import awsExports from './aws-exports';
//Required to properly configure cognito managed auth
Amplify.configure(awsExports);

//Override login page with custom theme
const components = {
  Header() {
    const { tokens } = useTheme();
    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image alt="Logo" src="https://oauth.cls.sba.gov/static/img/SBA-Logo-Horizontal-Reverse.626ceb82887e.png"/>
      </View>
    );
  },
  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.large} 0 0 ${tokens.space.large}`}
          level={3}
        >
          Sign in to Quicksight
        </Heading>
      );
    }
  }
}

//application code
export default function App() {
  return (
    <Authenticator hideSignUp={true} components={components}>
      {({ signOut, user }) => (
        <main>
          <Quicksight user={user} />
          <button onClick={signOut}>Sign out</button>
        </main>
      )}
    </Authenticator>
  );
}

//handles user post log in
function Quicksight(props) {
  //configure sts client
  const user = props.user
  const idToken = user.signInUserSession.idToken.jwtToken;
  const COGNITO_ID = user.signInUserSession.idToken.payload.iss.replace(/(^\w+:|^)\/\//, '');
  const loginData = {
    [COGNITO_ID]: idToken,
  };
  //Super janky, essentially have to assume itself in order to fix rolesession name...should be reviewed
  const cognitoUser = new STSClient({
    region: region,
    credentials: fromCognitoIdentityPool({
    clientConfig: { region: region}, // Configure the underlying CognitoIdentityClient.
    identityPoolId: identityPoolId,
    logins: loginData})
  });
  //Assume the same role...but with role session name honored
  //add email tag for auto QS login
  const emailTag = {
    Key: "Email",
    Value: user.signInUserSession.idToken.payload.email
  }
  const quicksightInput = {
    RoleArn: user.signInUserSession.idToken.payload["cognito:preferred_role"],
    RoleSessionName: user.signInUserSession.idToken.payload.email,
    Tags: [emailTag]
  }
  const assumeQSRole = new AssumeRoleCommand(quicksightInput);
  const qsRole = cognitoUser.send(assumeQSRole)
  .then(
    (result) => {
      const qsCreds = {
        "sessionId": result.Credentials.AccessKeyId,
        "sessionKey": result.Credentials.SecretAccessKey,
        "sessionToken": result.Credentials.SessionToken
      };
      //get federation token for console access
      //calls our API Gateway and verifies the Cognito token is valid before allowing token exchange
      const credsEncoded = encodeURIComponent(JSON.stringify(qsCreds));
      const uri = "https://signin.aws.amazon.com/federation?Action=getSigninToken&Session="+credsEncoded;
      const out = fetch(apiEndpoint,{
        method: "POST",
        headers: {
          Authorization: idToken
        },
        body: JSON.stringify({endpoint: uri})
      })
      .then(res => res.json())
      .then(
        (result) => {
          //redirect user to QS console
          let thisUrlEncoded = encodeURIComponent("https://"+window.location.hostname);
          let quicksightUrlEncoded = encodeURIComponent(`https://${region}.quicksight.aws.amazon.com/`);
          let quickSightSSO = "https://signin.aws.amazon.com/federation?Action=login&Issuer="+thisUrlEncoded+"&Destination="+quicksightUrlEncoded+"&SigninToken="+result.SigninToken
          window.location.replace(quickSightSSO)
        },
        (error) => {
          console.error(error)
        })
    },
    (error) => {
      console.error(error)
    });
  return <h5>Signing you in to Quicksight...You will be redirected momentarily</h5>
}